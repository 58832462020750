<template>
  <div>
    <div class="page-title">
      <h3>Lead Management</h3>
      <div class="page-breadcrumb">
        <ol class="breadcrumb">
          <li>
            <router-link
              :to="{name: 'home'}"
            >
              Home
            </router-link>
          </li>
          <li>
            <router-link
              :to="{name: 'dashboard'}"
            >
              Dashboard
            </router-link>
          </li>
          <li class="active">
            Lead Management
          </li>
        </ol>
      </div>
    </div>
    <div id="main-wrapper">
      <div
        id="totalStatsModal"
        aria-hidden="true"
        class="modal fade"
        role="dialog"
        tabindex="-1"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button
                aria-label="Close"
                class="close"
                data-dismiss="modal"
                type="button"
              >
                <span
                  aria-hidden="true"
                >&times;</span>
              </button>
              <h2 class="modal-title text-center">
                {{ (activeProductStats.name + ' Leads') }}
              </h2>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-xs-6 col-sm-6">
                  <button
                    id="productSourcesCSVButton"
                    class="btn-link pull-left"
                    style="padding-left: 0 !important; border: none"
                    type="button"
                    @click.stop.prevent="downloadSourcesReport('Product')"
                  >
                    Download Sources Report
                  </button>
                </div>
                <div class="col-xs-offset-1 col-sm-offset-2 col-xs-5 col-sm-4">
                  <div class="row">
                    <span class="col-xs-8">Show As Table: </span>
                    <toggle-button
                      v-model="statsDetailsAsTable"
                      :sync="true"
                      color="#22baa0"
                    />
                  </div>
                </div>
              </div>
              <div
                v-show="!statsDetailsAsTable"
                class="row"
              >
                <doughnut-chart
                  :chart-data="totalStatsChartData"
                  :options="totalStatsChartOptions"
                />
              </div>
              <div
                v-show="statsDetailsAsTable"
                class="row"
              >
                <div class="col-xs-12">
                  <div class="table-responsive">
                    <table
                      id="productStatsList"
                      class="table table-striped table-bordered"
                      style="width:100%"
                    >
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th>Count</th>
                          <th>Sources</th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-offset-1 col-md-10 col-lg-offset-2 col-lg-8 col-xs-12">
          <div
            class="panel info-box panel-white"
            style="overflow: visible"
          >
            <div
              class="panel-heading clearfix"
              style="overflow: visible"
            >
              <div
                class="col-md-4 hidden-sm hidden-xs"
                style="padding-left: 0 !important;"
              >
                <vue-typer
                  :repeat="0"
                  :text="(activeProductStats.name + ' Leads') || ' '"
                  caret-animation="smooth"
                />
              </div>
              <div class="float-right">
                <button
                  v-show="autoPlayStats !== null"
                  class="btn"
                  style="background-color: #e9edf2"
                  @click.stop.prevent="autoPlayStatsChange(false)"
                >
                  <span class="menu-icon fas fa-pause" />
                </button>
                <button
                  v-show="autoPlayStats === null"
                  class="btn"
                  style="background-color: #e9edf2"
                  @click.stop.prevent="autoPlayStatsChange(true)"
                >
                  <span class="menu-icon fas fa-play" />
                </button>
                <button
                  class="btn"
                  style="background-color: #e9edf2; margin-left: 10px"
                  title="Details"
                  @click.stop.prevent="showTotalStatsModal"
                >
                  <span class="menu-icon fas fa-chart-pie" />
                </button>
              </div>
              <div class="col-xs-7 col-md-5 col-lg-4 float-right">
                <label class="has-float-label no-margin-bottom">
                  <VueMultiselect
                    v-model="activeProductStats"
                    :allow-empty="false"
                    :multiple="false"
                    :options="getProductListForStats"
                    :placeholder="null"
                    :show-labels="false"
                    label="name"
                    track-by="id"
                  />
                </label>
              </div>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-xs-12 col-md-6">
                  <div class="info-box-stats">
                    <p class="counter">
                      {{ getTotalStats }}
                    </p>
                    <span class="info-box-title">Total Generated</span>
                  </div>
                  <div class="info-box-icon">
                    <i class="icon-like" />
                  </div>
                  <div class="info-box-progress">
                    <div class="progress progress-md progress-striped bs-n">
                      <div
                        :aria-valuenow="getPercentageForStats('Total')"
                        :style="{width: getPercentageForStats('Total') + '%'}"
                        aria-valuemax="100"
                        aria-valuemin="0"
                        class="progress-bar progress-bar-info"
                        role="progressbar"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-6">
                  <div class="info-box-stats">
                    <p class="counter">
                      {{ getActiveStats }}
                    </p>
                    <span class="info-box-title">Active</span>
                  </div>
                  <div class="info-box-icon">
                    <i class="icon-like" />
                  </div>
                  <div class="info-box-progress">
                    <div class="progress progress-md progress-striped bs-n">
                      <div
                        :aria-valuenow="getPercentageForStats('Active')"
                        :style="{width: getPercentageForStats('Active') + '%'}"
                        aria-valuemax="100"
                        aria-valuemin="0"
                        class="progress-bar progress-bar-info"
                        role="progressbar"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-white">
            <div class="panel-heading clearfix">
              <h4 class="panel-title">
                Upload New Leads
              </h4>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-xs-12 col-sm-6">
                  <label class="has-float-label">
                    <input
                      v-model="sourceName"
                      v-validate="'required'"
                      :class="errors.has('source.sourceName') ? 'error' : ''"
                      data-vv-as="source name"
                      data-vv-scope="source"
                      name="sourceName"
                      placeholder=" "
                      type="text"
                    >
                    <span class="float-label">Source Name <i
                      aria-hidden="true"
                      class="fas fa-asterisk text-danger"
                      style="font-size: 7px; vertical-align: text-top;"
                    /></span>
                    <span
                      v-show="errors.has('source.sourceName')"
                      class="help text-danger"
                    >{{ errors.first('source.sourceName') }}</span>
                  </label>
                </div>
                <div class="col-xs-3">
                  <div class="row">
                    <span class="col-xs-12 m-b-xs">Auto Enable: </span>
                    <toggle-button
                      v-model="sourceAutoEnable"
                      :sync="true"
                      class="col-xs-12"
                      color="#22baa0"
                    />
                  </div>
                </div>
                <div class="col-xs-3">
                  <div class="row">
                    <span class="col-xs-12 m-b-xs">Auto Allocate: </span>
                    <toggle-button
                      v-model="sourceAutoAllocate"
                      :sync="true"
                      class="col-xs-12"
                      color="#22baa0"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-6">
                  <label class="has-float-label">
                    <VueMultiselect
                      v-model="sourceProduct"
                      :allow-empty="false"
                      :class="{ 'placeholder-shown': (!sourceProduct || sourceProduct === '') }"
                      :multiple="false"
                      :options="_getProducts"
                      :placeholder="null"
                      :show-labels="false"
                      label="name"
                      track-by="id"
                    />
                    <span class="float-label">{{ $Amplify.I18n.get('Product') }}</span>
                    <span
                      v-show="errors.has('source.sourceProduct')"
                      class="help text-danger"
                    >{{ errors.first('source.sourceProduct') }}</span>
                  </label>
                </div>
                <div class="col-xs-6">
                  <label class="has-float-label">
                    <VueMultiselect
                      v-model="sourcePriority"
                      :allow-empty="false"
                      :class="{ 'placeholder-shown': (!sourcePriority || sourcePriority === '') }"
                      :multiple="false"
                      :options="_getPriorities"
                      :placeholder="null"
                      :show-labels="false"
                      label="name"
                      track-by="id"
                    />
                    <span class="float-label">{{ $Amplify.I18n.get('Priority') }}</span>
                    <span
                      v-show="errors.has('source.sourcePriority')"
                      class="help text-danger"
                    >{{ errors.first('source.sourcePriority') }}</span>
                  </label>
                </div>
              </div>
              <div
                v-show="sourceAutoEnable"
                class="row"
              >
                <span class="col-xs-12">Time: </span>
                <date-range-picker
                  ref="picker"
                  v-model="sourceEnableAt"
                  :locale-data="getLocaleData"
                  :min-date="getDateToday"
                  :opens="'right'"
                  :ranges="dateRangePresets"
                  :single-date-picker="true"
                  :time-picker="true"
                  :time-picker-increment="60"
                  :time-picker24hour="false"
                  class="col-xs-12"
                >
                  <div
                    slot="input"
                    slot-scope="picker"
                    style="min-width: 250px;"
                  >
                    {{ picker.startDate | date }}
                  </div>
                </date-range-picker>
              </div>
              <div
                v-show="!sourceAutoAllocate"
                class="row m-t-sm"
              >
                <div class="col-xs-12">
                  <label class="has-float-label">
                    <VueMultiselect
                      v-model="sourceCampaign"
                      :allow-empty="false"
                      :class="{ 'placeholder-shown': (!sourceCampaign || sourceCampaign === '') }"
                      :multiple="false"
                      :options="_getCampaigns"
                      :placeholder="null"
                      :show-labels="false"
                      label="name"
                      track-by="id"
                    />
                    <span class="float-label">{{ $Amplify.I18n.get('Campaign') }}</span>
                    <span
                      v-show="errors.has('source.sourceCampaign')"
                      class="help text-danger"
                    >{{ errors.first('source.sourceCampaign') }}</span>
                  </label>
                </div>
              </div>
              <div
                v-if="!sourceAutoAllocate && sourceCampaign && sourceCampaign !== ''"
                class="row"
              >
                <div
                  v-for="member in sourceCampaignMembers"
                  :key="member.username"
                >
                  <span class="col-xs-6 m-t-md text-right">{{ member.name + ':' }}</span>
                  <div class="col-xs-2">
                    <label class="has-float-label">
                      <input
                        v-model="member.allocationCount"
                        name="sourceMember"
                        placeholder=" "
                        type="text"
                      >
                    </label>
                  </div>
                </div>
              </div>
              <div class="row m-t-sm">
                <div class="col-md-12">
                  <div
                    id="csvUpload"
                    class="dropzone"
                  >
                    <!-- Not displayed, just for Dropzone's `dictDefaultMessage` option -->
                    <div
                      id="dropzone-message"
                      style="display: none"
                    >
                      <span class="dropzone-title">Drop your file here or click to select</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row m-t-lg">
                <div class="col-xs-2">
                  <button
                    id="uploadFile"
                    class="btn btn-default btn-info"
                    type="button"
                    @click.stop.prevent="addNew('source')"
                  >
                    Upload
                  </button>
                </div>
                <div
                  class="col-xs-10 m-t-xs text-right"
                  style="font-size: 14px; font-weight: 600"
                >
                  Status: <span :class="uploadStatus.css">{{ uploadStatus.text }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="panel panel-white">
            <div class="panel-heading clearfix">
              <h4 class="panel-title">
                Create New Campaign
              </h4>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-xs-12">
                  <label class="has-float-label">
                    <input
                      v-model="leadPoolName"
                      v-validate="'required'"
                      :class="errors.has('pool.leadPoolName') ? 'error' : ''"
                      data-vv-as="pool name"
                      data-vv-scope="pool"
                      name="leadPoolName"
                      placeholder=" "
                      type="text"
                    >
                    <span class="float-label">Campaign Name <i
                      aria-hidden="true"
                      class="fas fa-asterisk text-danger"
                      style="font-size: 7px; vertical-align: text-top;"
                    /></span>
                    <span
                      v-show="errors.has('pool.leadPoolName')"
                      class="help text-danger"
                    >{{ errors.first('pool.leadPoolName') }}</span>
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12">
                  <label class="has-float-label">
                    <VueMultiselect
                      v-model="leadPoolSources"
                      :allow-empty="true"
                      :class="{ 'placeholder-shown': (!leadPoolSources || leadPoolSources === '') }"
                      :multiple="true"
                      :options="_getSources"
                      :placeholder="null"
                      :show-labels="false"
                      label="name"
                      track-by="id"
                    />
                    <span class="float-label">{{ $Amplify.I18n.get('Sources') }}</span>
                    <span
                      v-show="errors.has('pool.leadPoolSources')"
                      class="help text-danger"
                    >{{ errors.first('pool.leadPoolSources') }}</span>
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12">
                  <label class="has-float-label">
                    <VueMultiselect
                      v-model="leadPoolMembers"
                      :allow-empty="true"
                      :class="{ 'placeholder-shown': (!leadPoolMembers || leadPoolMembers === '') }"
                      :custom-label="getUserName"
                      :multiple="true"
                      :options="_getMembers()"
                      :placeholder="null"
                      :show-labels="false"
                      track-by="username"
                    />
                    <span class="float-label">{{ $Amplify.I18n.get('Members') }}</span>
                    <span
                      v-show="errors.has('pool.leadPoolMembers')"
                      class="help text-danger"
                    >{{ errors.first('pool.leadPoolMembers') }}</span>
                  </label>
                </div>
              </div>
              <div class="row m-t-lg">
                <div class="col-xs-2">
                  <button
                    id="createLeadPool"
                    class="btn btn-default btn-info"
                    type="button"
                    @click.stop.prevent="addNew('pool')"
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-white">
            <div class="panel-heading clearfix">
              <h4 class="panel-title">
                Campaigns
              </h4>
            </div>
            <div class="panel-body">
              <div class="table-responsive">
                <table
                  id="leadPoolsList"
                  class="table table-striped table-bordered"
                  style="width:100%"
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Sources</th>
                      <th>Members</th>
                      <th>Active Leads</th>
                      <th>Untouched Leads</th>
                      <th>Interested Leads</th>
                      <th>Call Later Leads</th>
                      <th>Closed Leads</th>
                      <th>Last Updated</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-white">
            <div class="panel-heading clearfix">
              <h4 class="panel-title">
                Sources
              </h4>
            </div>
            <div class="panel-body">
              <div class="table-responsive">
                <table
                  id="sourcesList"
                  class="table table-striped table-bordered"
                  style="width:100%"
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Product</th>
                      <th>Enabled</th>
                      <th>Priority</th>
                      <th>Campaign</th>
                      <th>Active Leads</th>
                      <th>Generated Leads</th>
                      <th>Upload Count</th>
                      <th>Enable Time</th>
                      <th>Last Updated</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="leadPoolModal"
      aria-hidden="true"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span
                aria-hidden="true"
              >&times;</span>
            </button>
            <h2 class="modal-title text-center">
              Campaign Details
            </h2>
          </div>
          <div class="modal-body">
            <div
              class="row"
              style="margin-top: 25px"
            >
              <div class="form-group col-sm-6">
                <label class="has-float-label">
                  <input
                    v-model="leadPoolDetails.name"
                    v-validate="'required'"
                    :class="errors.has('leadPoolDetails.name') ? 'error' : ''"
                    data-vv-as="status name"
                    data-vv-scope="leadPoolDetails"
                    name="leadPoolDetails.name"
                    placeholder=" "
                    type="text"
                  >
                  <span class="float-label">
                    Name
                    <i
                      aria-hidden="true"
                      class="fas fa-asterisk text-danger"
                      style="font-size: 7px; vertical-align: text-top;"
                    />
                  </span>
                  <span
                    v-show="errors.has('leadPoolDetails.name')"
                    class="help text-danger"
                  >{{ errors.first('leadPoolDetails.name') }}</span>
                </label>
              </div>
              <div class="form-group col-sm-offset-1 col-sm-5">
                <label class="form-details-label">Date Added:</label>
                <p class="text-muted">
                  {{ prettyPrint(leadPoolDetails.createdAt, 'Date') }}
                </p>
              </div>
            </div>
            <div role="tabpanel">
              <!-- Nav tabs -->
              <ul
                class="nav nav-tabs"
                role="tablist"
              >
                <li
                  class="active"
                  role="presentation"
                >
                  <a
                    data-toggle="tab"
                    href="#tab1"
                    role="tab"
                  >
                    Sources
                  </a>
                </li>
                <li role="presentation">
                  <a
                    data-toggle="tab"
                    href="#tab2"
                    role="tab"
                  >
                    Members
                  </a>
                </li>
                <li role="presentation">
                  <a
                    data-toggle="tab"
                    href="#tab3"
                    role="tab"
                  >
                    Statistics
                  </a>
                </li>
              </ul>
              <!-- Tab panes -->
              <div class="tab-content">
                <div
                  id="tab1"
                  class="tab-pane active fade in"
                  role="tabpanel"
                >
                  <div class="row">
                    <div class="form-group col-xs-12">
                      <label class="has-float-label">
                        <label class="has-float-label">
                          <VueMultiselect
                            v-model="leadPoolDetails.newSources"
                            :allow-empty="true"
                            :block-keys="['Delete']"
                            :class="{ 'placeholder-shown': (!leadPoolDetails.newSources || leadPoolDetails.newSources === '') }"
                            :hide-selected="true"
                            :limit="1"
                            :multiple="true"
                            :options="_getSources"
                            :placeholder="null"
                            :show-labels="false"
                            label="name"
                            track-by="id"
                          />
                          <span class="float-label">Add Sources:</span>
                          <span
                            v-show="errors.has('leadPoolDetails.newSources')"
                            class="help text-danger"
                          >{{ errors.first('leadPoolDetails.newSources') }}</span>
                        </label>
                      </label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-12">
                      <div class="table-responsive">
                        <table
                          id="leadPoolSourcesList"
                          class="table table-striped table-bordered"
                          style="width:100%"
                        >
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Product</th>
                              <th>Enabled</th>
                              <th />
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="tab2"
                  class="tab-pane fade"
                  role="tabpanel"
                >
                  <div class="row">
                    <div class="form-group col-xs-12">
                      <label class="has-float-label">
                        <label class="has-float-label">
                          <VueMultiselect
                            v-model="leadPoolDetails.newMembers"
                            :allow-empty="true"
                            :class="{ 'placeholder-shown': (!leadPoolDetails.newMembers || leadPoolDetails.newMembers === '') }"
                            :custom-label="getUserName"
                            :hide-selected="true"
                            :limit="1"
                            :multiple="true"
                            :options="_getMembers(leadPoolDetails.id)"
                            :placeholder="null"
                            :show-labels="false"
                            track-by="username"
                          />
                          <span class="float-label">Add Members:</span>
                          <span
                            v-show="errors.has('leadPoolDetails.newMembers')"
                            class="help text-danger"
                          >{{ errors.first('leadPoolDetails.newMembers') }}</span>
                        </label>
                      </label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-12">
                      <div class="table-responsive">
                        <table
                          id="leadPoolMembersList"
                          class="table table-striped table-bordered"
                          style="width:100%"
                        >
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Supervisor</th>
                              <th />
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="tab3"
                  class="tab-pane fade"
                  role="tabpanel"
                >
                  <div class="row">
                    <div class="col-xs-6 col-sm-6">
                      <button
                        v-if="leadPoolDetails.currentSources && leadPoolDetails.currentSources.length > 0"
                        id="campaignSourcesCSVButton"
                        class="btn-link pull-left"
                        style="padding-left: 0 !important; border: none"
                        type="button"
                        @click.stop.prevent="downloadSourcesReport('Campaign')"
                      >
                        Download Sources Report
                      </button>
                    </div>
                    <div class="col-xs-offset-1 col-sm-offset-2 col-xs-5 col-sm-4">
                      <div class="row">
                        <span class="col-xs-8">Show As Table: </span>
                        <toggle-button
                          v-model="statsDetailsAsTable"
                          :sync="true"
                          color="#22baa0"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-show="!statsDetailsAsTable"
                    class="row"
                  >
                    <doughnut-chart
                      :chart-data="leadPoolDetailsChartData"
                      :options="leadPoolDetailsChartOptions"
                    />
                  </div>
                  <div
                    v-show="statsDetailsAsTable"
                    class="row"
                  >
                    <div class="col-xs-12">
                      <div class="table-responsive">
                        <table
                          id="leadPoolStatsList"
                          class="table table-striped table-bordered"
                          style="width:100%"
                        >
                          <thead>
                            <tr>
                              <th>Status</th>
                              <th>Count</th>
                              <th />
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              id="deleteSelectedPool"
              class="btn btn-default btn-danger pull-left"
              type="button"
              @click.stop.prevent="deleteSelectedPool"
            >
              Delete
            </button>
            <button
              class="btn btn-default"
              data-dismiss="modal"
              type="button"
            >
              Close
            </button>
            <button
              id="updatePoolDetailsButton"
              class="btn btn-default btn-success"
              type="button"
              @click.stop.prevent="addNew('leadPoolDetails')"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      id="sourceModal"
      aria-hidden="true"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span
                aria-hidden="true"
              >&times;</span>
            </button>
            <h2 class="modal-title text-center">
              Source Details
            </h2>
          </div>
          <div class="modal-body">
            <div
              class="row"
              style="margin-top: 25px"
            >
              <div class="form-group col-sm-6 col-sm-offset-0 col-xs-offset-1 col-xs-5">
                <label class="has-float-label">
                  <input
                    v-model="sourceDetails.name"
                    v-validate="'required'"
                    :class="errors.has('sourceDetails.name') ? 'error' : ''"
                    data-vv-as="status name"
                    data-vv-scope="sourceDetails"
                    name="sourceDetails.name"
                    placeholder=" "
                    type="text"
                  >
                  <span class="float-label">
                    Name
                    <i
                      aria-hidden="true"
                      class="fas fa-asterisk text-danger"
                      style="font-size: 7px; vertical-align: text-top;"
                    />
                  </span>
                  <span
                    v-show="errors.has('sourceDetails.name')"
                    class="help text-danger"
                  >{{ errors.first('sourceDetails.name') }}</span>
                </label>
              </div>
              <div class="col-md-2 col-sm-3 col-xs-3">
                <div class="row">
                  <span class="col-xs-12 m-b-xs">Enabled: </span>
                  <toggle-button
                    v-model="sourceDetails.enabled"
                    :sync="true"
                    class="col-xs-12"
                    color="#22baa0"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-4 col-sm-offset-0 col-xs-offset-1 col-xs-10">
                <label class="has-float-label">
                  <VueMultiselect
                    v-model="sourceDetails.priority"
                    :allow-empty="false"
                    :class="{ 'placeholder-shown': (!sourceDetails.priority || sourceDetails.priority === '') }"
                    :multiple="false"
                    :options="_getPriorities"
                    :placeholder="null"
                    :show-labels="false"
                    label="name"
                    track-by="id"
                  />
                  <span class="float-label">{{ $Amplify.I18n.get('Priority') }}</span>
                  <span
                    v-show="errors.has('sourceDetails.priority')"
                    class="help text-danger"
                  >{{ errors.first('sourceDetails.priority') }}</span>
                </label>
              </div>
              <div class="form-group col-sm-3 col-sm-offset-0 col-xs-offset-1 col-xs-6">
                <label class="form-details-label">Product:</label>
                <p class="text-muted">
                  {{ prettyPrint(sourceDetails.product, 'Product') }}
                </p>
              </div>
              <div class="form-group col-sm-5 col-xs-5">
                <label class="form-details-label">Date Added:</label>
                <p class="text-muted">
                  {{ prettyPrint(sourceDetails.createdAt, 'Date') }}
                </p>
              </div>
            </div>
            <div class="row m-b-sm">
              <div class="col-xs-12 text-center">
                <h4>Lead Stats</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-6 col-sm-6">
                <button
                  v-if="sourceDetails.duplicates && sourceDetails.duplicates.length > 0"
                  id="sourceDuplicatesButton"
                  class="btn-link pull-left"
                  style="padding-left: 0 !important; border: none"
                  type="button"
                  @click.stop.prevent="downloadSourceDuplicatesReport"
                >
                  Download Duplicates Report
                </button>
              </div>
              <div class="col-xs-offset-1 col-sm-offset-2 col-xs-5 col-sm-4">
                <div class="row">
                  <span class="col-xs-8">Show As Table: </span>
                  <toggle-button
                    v-model="statsDetailsAsTable"
                    :sync="true"
                    color="#22baa0"
                  />
                </div>
              </div>
            </div>
            <div
              v-show="!statsDetailsAsTable"
              class="row"
            >
              <doughnut-chart
                :chart-data="sourceDetailsChartData"
                :options="sourceDetailsChartOptions"
              />
            </div>
            <div
              v-show="statsDetailsAsTable"
              class="row"
            >
              <div class="col-xs-12">
                <div class="table-responsive">
                  <table
                    id="sourceStatsList"
                    class="table table-striped table-bordered"
                    style="width:100%"
                  >
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Count</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              v-if="!_isSystemSource"
              id="deleteSelectedSource"
              class="btn btn-default btn-danger pull-left"
              type="button"
              @click.stop.prevent="deleteSelectedSource"
            >
              Delete
            </button>
            <button
              class="btn btn-default"
              data-dismiss="modal"
              type="button"
            >
              Close
            </button>
            <button
              id="updateSourceDetailsButton"
              class="btn btn-default btn-success"
              type="button"
              @click.stop.prevent="addNew('sourceDetails')"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {API, graphqlOperation, Storage} from "aws-amplify";
import * as customQueries from "../../graphql/customQueries";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import * as subscriptions from "../../graphql/subscriptions";
import Dropzone from "dropzone";
import {chartColours, priorities, uploadStatuses} from "@/constants";
import VueMultiselect from "vue-multiselect";
import DateRangePicker from "vue2-daterange-picker";
import DoughnutChart from '../../components/DoughnutChart';
import {VueTyper} from 'vue-typer';
import Papa from "papaparse";

export default {
  name: "LeadManagement",
  components: {
    VueMultiselect,
    DateRangePicker,
    DoughnutChart,
    VueTyper
  },
  beforeRouteLeave(to, from, next) {
    let leadPoolModal = $("#leadPoolModal");
    let sourceModal = $("#sourceModal");
    if ((leadPoolModal.data('bs.modal') || {}).isShown) {
      leadPoolModal.modal('hide');
      document.getElementById('leadPoolsList').scrollIntoView({behavior: 'instant'});
      next(false);
    } else if ((sourceModal.data('bs.modal') || {}).isShown) {
      sourceModal.modal('hide');
      document.getElementById('sourcesList').scrollIntoView({behavior: 'instant'});
      next(false);
    } else {
      next();
    }
  },
  filters: {
    date: function (date) {
      return moment(date).format('DD-MM-YYYY hh:mm A');
    }
  },
  data() {
    return {
      dateRangePresets: {
        'Today': [moment().add(1, 'hours').startOf('hour'), moment().add(1, 'hours').startOf('hour')],
        'Tomorrow': [moment().add(1, 'days').startOf('day'), moment().add(1, 'days').startOf('day')],
        'Next week': [moment().add(1, 'weeks').startOf('week'), moment().add(1, 'weeks').startOf('week')],
        'In 10 Days': [moment().add(10, 'days').startOf('day'), moment().add(10, 'days').startOf('day')],
        'Next month': [moment().add(1, 'months').startOf('month'), moment().add(1, 'months').startOf('month')],
      },
      sourceName: null,
      sourceProduct: null,
      sourcePriority: null,
      sourceCampaign: null,
      sourceCampaignMembers: [],
      sourceAutoAllocate: true,
      sourceAutoEnable: false,
      sourceEnableAt: {
        startDate: moment().add(1, 'hours').startOf('hour')
      },
      sourcesTable: null,
      sourcesData: [],
      sourceDetails: {},
      leadPoolName: null,
      leadPoolSources: [],
      leadPoolMembers: [],
      leadPoolsTable: null,
      leadPoolsData: [],
      leadPoolDetails: {},
      fileUploader: null,
      uploadStatus: uploadStatuses.success,
      createSourceSubscription: null,
      updateSourceSubscription: null,
      createLeadPoolSubscription: null,
      updateLeadPoolSubscription: null,
      sourceDetailsChartData: {
        labels: [],
        datasets: []
      },
      sourceDetailsChartOptions: {},
      leadPoolDetailsChartData: {
        labels: [],
        datasets: []
      },
      leadPoolDetailsChartOptions: {},
      totalStatsChartData: {
        labels: [],
        datasets: []
      },
      totalStatsChartOptions: {},
      activeProductStats: {
        id: 'total',
        name: 'Total'
      },
      autoPlayStats: null,
      leadPoolDetailsSourceTable: null,
      leadPoolDetailsMemberTable: null,
      leadPoolDetailsStatsTable: null,
      sourceDetailsStatsTable: null,
      productDetailsStatsTable: null,
      statsDetailsAsTable: false
    }
  },
  computed: {
    ...mapGetters([
      "getUsers",
      "getProducts"
    ]),
    getProductListForStats() {
      return [
        {
          id: 'total',
          name: 'Total'
        },
        ...this.getProducts
      ]
    },
    getPercentageForStats() {
      return (type) => {
        if (type === 'Active') {
          return (Math.round((isNaN(this.getActiveStats / _.sumBy(this.sourcesData, 'activeCount')) ? 0 : (this.getActiveStats / _.sumBy(this.sourcesData, 'activeCount'))) * 100));
        } else if (type === 'Total') {
          return (Math.round((isNaN(this.getTotalStats / _.sumBy(this.sourcesData, 'leadCount')) ? 0 : (this.getTotalStats / _.sumBy(this.sourcesData, 'leadCount'))) * 100));
        } else {
          return 0;
        }
      }
    },
    getTotalStats() {
      if (!this.activeProductStats) {
        return 0;
      } else {
        if (this.activeProductStats.id === 'total') {
          return _.sumBy(this.sourcesData, 'leadCount');
        } else {
          return _.sumBy(_.filter(this.sourcesData, (source) => {
            return source.product === this.activeProductStats.id;
          }), 'leadCount');
        }
      }
    },
    getActiveStats() {
      if (!this.activeProductStats) {
        return 0;
      } else {
        if (this.activeProductStats.id === 'total') {
          return _.sumBy(this.sourcesData, 'activeCount');
        } else {
          return _.sumBy(_.filter(this.sourcesData, (source) => {
            return source.product === this.activeProductStats.id;
          }), 'activeCount');
        }
      }
    },
    _isSystemSource() {
      return this.sourceDetails && (this.sourceDetails.isCrossLeadsSource === "true");
    },
    _getSources: function () {
      let existingPoolSources = _.flattenDeep(_.map(this.leadPoolsData, 'sources'));
      return _.sortBy(_.omitBy(this.sourcesData, (source) => {
        return _.includes(existingPoolSources, source.id);
      }), 'name');
    },
    _getCampaigns: function () {
      return _.sortBy(this.leadPoolsData, 'name');
    },
    _getProducts: function () {
      return _.sortBy(this.getProducts, 'name');
    },
    _getPriorities: function () {
      return _.sortBy(_.values(priorities), 'level');
    },
    _getMembers() {
      return (currentPoolId = null) => {
        let existingPoolMembers = currentPoolId !== null ? _.flattenDeep(_.map(this.leadPoolDetails.currentMembers, 'id')) : [];
        return _.sortBy(_.omitBy(this.getUsers, (user) => {
          return _.includes(existingPoolMembers, user.id);
        }), (user) => {
          return this.getUserName(user);
        });
      };
    },
    getLocaleData() {
      return {
        format: 'DD-MM-YYYY hh:mm A',
        firstDay: moment.localeData().firstDayOfWeek()
      }
    },
    getDateToday() {
      return moment().format('LL');
    }
  },
  watch: {
    sourceCampaign(newValue) {
      if (newValue && newValue !== '') {
        this.sourceCampaignMembers = _.map(newValue.members, (member) => {
          return {
            username: member,
            name: this.prettyPrint(member, 'User'),
            allocationCount: null
          };
        });
      } else {
        this.sourceCampaignMembers = [];
      }
    },
    activeProductStats() {
      this.updateTotalStatsChart();
    }
  },
  created() {
    Dropzone.autoDiscover = false;
  },
  beforeDestroy() {
    this.unsubscribeAll();
  },
  async mounted() {
    $('#sidebar').removeClass('visible');
    $('.page-inner').removeClass('sidebar-visible');

    let options = {
      url: '/',
      method: 'put',
      maxFiles: 1,
      maxfilesexceeded: function (file) {
        this.removeAllFiles();
        this.addFile(file);
      },
      acceptedFiles: ".csv",
      capture: 'file',
      addRemoveLinks: true,
      parallelUploads: 1,
      uploadMultiple: false,
      header: '',
      dictDefaultMessage: document.querySelector('#dropzone-message').innerHTML,
      autoProcessQueue: false
    };

    // Instantiate DropZone
    this.fileUploader = new Dropzone("div#csvUpload", options);

    this.unsubscribeAll();

    this.createSourceSubscription = API.graphql(graphqlOperation(subscriptions.onCreateLeadSource
    )).subscribe({
      next: (update) => {
        let createdLeadSource = update.value.data.onCreateLeadSource;
        let oldList = _.cloneDeep(this.sourcesData);
        let sourceIndex = _.findIndex(oldList, (source) => {
          return source.id === createdLeadSource.id;
        });
        if (sourceIndex === -1) {
          this.sourcesData.push(createdLeadSource);
          this.sourcesTable.row
              .add(createdLeadSource)
              .draw();
        }
      }
    });

    this.updateSourceSubscription = API.graphql(graphqlOperation(subscriptions.onUpdateLeadSource
    )).subscribe({
      next: (update) => {
        let updatedLeadSource = update.value.data.onUpdateLeadSource;
        let oldList = _.cloneDeep(this.sourcesData);
        let sourceIndex = _.findIndex(oldList, (source) => {
          return source.id === updatedLeadSource.id;
        });
        if (sourceIndex !== -1) {
          oldList[sourceIndex] = updatedLeadSource;
          this.sourcesData = oldList;
          this.sourcesTable.row("#" + updatedLeadSource.id)
              .invalidate()
              .data(updatedLeadSource)
              .draw();
        }
      }
    });

    this.createLeadPoolSubscription = API.graphql(graphqlOperation(subscriptions.onCreateLeadPool
    )).subscribe({
      next: (update) => {
        let createdLeadPool = update.value.data.onCreateLeadPool;
        let oldList = _.cloneDeep(this.leadPoolsData);
        let poolIndex = _.findIndex(oldList, (pool) => {
          return pool.id === createdLeadPool.id;
        });
        if (poolIndex === -1) {
          this.leadPoolsData.push(createdLeadPool);
          this.leadPoolsTable.row
              .add(createdLeadPool)
              .draw();

          this.sourcesTable.clear();
          this.sourcesTable.rows
              .add(this.sourcesData)
              .draw();
        }
      }
    });

    this.updateLeadPoolSubscription = API.graphql(graphqlOperation(subscriptions.onUpdateLeadPool
    )).subscribe({
      next: (update) => {
        let updatedLeadPool = update.value.data.onUpdateLeadPool;
        let oldList = _.cloneDeep(this.leadPoolsData);
        let poolIndex = _.findIndex(oldList, (pool) => {
          return pool.id === updatedLeadPool.id;
        });
        if (poolIndex !== -1) {
          oldList[poolIndex] = updatedLeadPool;
          this.leadPoolsData = oldList;
          this.leadPoolsTable.row("#" + updatedLeadPool.id)
              .invalidate()
              .data(updatedLeadPool)
              .draw();

          this.sourcesTable.clear();
          this.sourcesTable.rows
              .add(this.sourcesData)
              .draw();
        }
      }
    });

    await this.fetchLeadSources();
    await this.fetchLeadPools();

    this.createSourcesDatatable();
    this.createLeadPoolsDatatable();
    this.createLeadPoolDetailsSourceDatatable();
    this.createLeadPoolDetailsMemberDatatable();
    this.createLeadPoolStatsDatatable();
    this.createSourceStatsDatatable();
    this.createProductStatsDatatable();
    this.updateTotalStatsChart();

    this.autoPlayStats = setInterval(() => {
      let index = _.findIndex(this.getProductListForStats, (product) => {
        return product.id === this.activeProductStats.id;
      });
      if (index !== -1) {
        index++;
        if (index >= this.getProductListForStats.length) {
          index = 0;
        }
        this.activeProductStats = this.getProductListForStats[index];
      }
    }, 5000);
  },
  methods: {
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    getUserName: function (user) {
      if (user) {
        let name = _.capitalize(user.firstName);

        if (user.lastName) {
          name += " " + _.capitalize(user.lastName);
        }
        return name;
      } else {
        return '';
      }
    },
    unsubscribeAll: function () {
      if (this.createSourceSubscription) {
        this.createSourceSubscription.unsubscribe();
      }
      if (this.updateSourceSubscription) {
        this.updateSourceSubscription.unsubscribe();
      }
      if (this.createLeadPoolSubscription) {
        this.createLeadPoolSubscription.unsubscribe();
      }
      if (this.updateLeadPoolSubscription) {
        this.updateLeadPoolSubscription.unsubscribe();
      }
    },
    showTotalStatsModal: function () {
      $('#totalStatsModal').modal('show');
    },
    autoPlayStatsChange: function (turnOn) {
      if (turnOn && this.autoPlayStats === null) {
        this.autoPlayStats = setInterval(() => {
          let index = _.findIndex(this.getProductListForStats, (product) => {
            return product.id === this.activeProductStats.id;
          });
          if (index !== -1) {
            index++;
            if (index >= this.getProductListForStats.length) {
              index = 0;
            }
            this.activeProductStats = this.getProductListForStats[index];
          }
        }, 5000);
      } else {
        clearInterval(this.autoPlayStats);
        this.autoPlayStats = null;
      }
    },
    async fetchLeadSources(nextPage = null) {
      const {
        data: {
          listLeadSources: {items, nextToken}
        }
      } = await API.graphql(graphqlOperation(queries.listLeadSources, {
        limit: 9999,
        nextToken: nextPage
      }));
      this.sourcesData = _.uniqBy(_.concat(this.sourcesData, items), 'id');
      nextPage = nextToken;
      if (nextPage) {
        await this.fetchLeadSources(nextPage);
      }
    },
    async fetchLeadPools(nextPage = null) {
      const {
        data: {
          listLeadPools: {items, nextToken}
        }
      } = await API.graphql(graphqlOperation(queries.listLeadPools, {
        limit: 9999,
        nextToken: nextPage
      }));
      this.leadPoolsData = _.uniqBy(_.concat(this.leadPoolsData, items), 'id');
      nextPage = nextToken;
      if (nextPage) {
        await this.fetchLeadPools(nextPage);
      }
    },
    createSourcesDatatable: function () {
      let buttons = [
        {
          extend: 'pageLength',
          text: '<i class="fas fa-filter"></i>',
          titleAttr: 'Number of Rows'
        },
        {
          extend: 'colvis',
          text: '<i class="fas fa-eye"></i>',
          titleAttr: 'Column Visibility',
          columns: ':not(.noVis)'
        },
        {
          extend: 'excelHtml5',
          autoFilter: true,
          text: '<i class="far fa-file-excel"></i>',
          titleAttr: 'Excel'
        }
      ];

      this.sourcesTable = $('#sourcesList').DataTable({
        processing: true,
        language: {
          'loadingRecords': '&nbsp;',
          'processing': '<i class="fas fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'
        },
        dom: 'Bfrtip',
        lengthMenu: [
          [10, 25, 50, 100, -1],
          ['10 rows', '25 rows', '50 rows', '100 rows', 'Show all']
        ],
        data: this.sourcesData,
        rowId: 'id',
        columns: [
          {
            data: 'name'
          },
          {
            data: 'product',
            defaultContent: ''
          },
          {
            data: 'enabled'
          },
          {
            data: 'priority',
            defaultContent: ""
          },
          {
            data: 'id',
            defaultContent: ''
          },
          {
            data: 'activeCount',
            defaultContent: ""
          },
          {
            data: 'leadCount',
            defaultContent: ""
          },
          {
            data: 'totalCount',
            defaultContent: ""
          },
          {
            data: 'enableAtEpoch',
            defaultContent: ""
          },
          {
            data: 'updatedAt',
            defaultContent: ""
          },
          {
            data: 'createdAt',
            defaultContent: ""
          }
        ],
        columnDefs: [
          {
            render: (data) => {
              return this.prettyPrint(data, 'Product');
            },
            targets: 1
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Priority');
            },
            targets: 3
          },
          {
            render: (data) => {
              let pool = _.find(this.leadPoolsData, (pool) => {
                return _.includes(pool.sources, data);
              });
              if (pool && pool.name !== '') {
                return pool.name;
              } else {
                return '';
              }
            },
            targets: 4
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Epoch');
            },
            targets: 8
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Date');
            },
            visible: false,
            targets: [9, 10]
          }
        ],
        buttons: buttons,
        search: {
          regex: true,
          smart: false
        },
        order: [[10, "desc"]]
      });

      this.sourcesTable.buttons()
          .container()
          .appendTo('#sourcesList_wrapper .col-sm-6:eq(0)');

      this.setupSourceModal();
    },
    createLeadPoolsDatatable: function () {
      let buttons = [
        {
          extend: 'pageLength',
          text: '<i class="fas fa-filter"></i>',
          titleAttr: 'Number of Rows'
        },
        {
          extend: 'colvis',
          text: '<i class="fas fa-eye"></i>',
          titleAttr: 'Column Visibility',
          columns: ':not(.noVis)'
        },
        {
          extend: 'excelHtml5',
          autoFilter: true,
          text: '<i class="far fa-file-excel"></i>',
          titleAttr: 'Excel'
        }
      ];

      this.leadPoolsTable = $('#leadPoolsList').DataTable({
        processing: true,
        language: {
          'loadingRecords': '&nbsp;',
          'processing': '<i class="fas fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'
        },
        dom: 'Bfrtip',
        lengthMenu: [
          [10, 25, 50, 100, -1],
          ['10 rows', '25 rows', '50 rows', '100 rows', 'Show all']
        ],
        data: this.leadPoolsData,
        rowId: 'id',
        columns: [
          {
            data: 'name'
          },
          {
            data: 'sources',
            defaultContent: ""
          },
          {
            data: 'members',
            defaultContent: ""
          },
          {
            data: 'activeCount',
            defaultContent: ""
          },
          {
            data: 'untouchedCount',
            defaultContent: ""
          },
          {
            data: 'interestedCount',
            defaultContent: ""
          },
          {
            data: 'callLaterCount',
            defaultContent: ""
          },
          {
            data: 'closedCount',
            defaultContent: ""
          },
          {
            data: 'updatedAtEpoch',
            defaultContent: ""
          }
        ],
        columnDefs: [
          {
            render: (data) => {
              if (!data || data.length < 1) {
                return '';
              } else if (data.length > 5) {
                return data.length + ' sources';
              } else {
                return _.map(data, (sourceId) => {
                  return this.prettyPrint(sourceId, 'Source')
                }).join(', ');
              }
            },
            targets: 1
          },
          {
            render: (data) => {
              if (!data || data.length < 1) {
                return '';
              } else if (data.length > 5) {
                return data.length + ' members';
              } else {
                return _.map(data, (username) => {
                  return this.prettyPrint(username, 'User')
                }).join(', ');
              }
            },
            targets: 2
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Epoch');
            },
            visible: false,
            targets: 8
          }
        ],
        buttons: buttons,
        search: {
          regex: true,
          smart: false
        },
        order: [[8, "desc"]]
      });

      this.leadPoolsTable.buttons()
          .container()
          .appendTo('#leadPoolsList_wrapper .col-sm-6:eq(0)');

      this.setupLeadPoolModal();
    },
    createLeadPoolDetailsSourceDatatable: function () {
      const leadPoolSourcesTable = $('#leadPoolSourcesList');
      let buttons = [
        {
          extend: 'pageLength',
          text: '<i class="fas fa-filter"></i>',
          titleAttr: 'Number of Rows'
        },
        {
          extend: 'colvis',
          text: '<i class="fas fa-eye"></i>',
          titleAttr: 'Column Visibility',
          columns: ':not(.noVis)'
        },
        {
          extend: 'excelHtml5',
          autoFilter: true,
          text: '<i class="far fa-file-excel"></i>',
          titleAttr: 'Excel'
        }
      ];

      this.leadPoolDetailsSourceTable = leadPoolSourcesTable.DataTable({
        processing: true,
        language: {
          'loadingRecords': '&nbsp;',
          'processing': '<i class="fas fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'
        },
        dom: 'Bfrtip',
        lengthMenu: [
          [10, 25, 50, 100, -1],
          ['10 rows', '25 rows', '50 rows', '100 rows', 'Show all']
        ],
        data: this.leadPoolDetails.currentSources,
        rowId: 'id',
        columns: [
          {
            data: 'name'
          },
          {
            data: 'product',
            defaultContent: ''
          },
          {
            data: 'enabled'
          },
          {
            data: null,
            className: "remove-icon",
            defaultContent: '<i class="row-delete-icon fas fa-trash-alt"/>'
          }
        ],
        columnDefs: [
          {
            render: (data) => {
              return this.prettyPrint(data, 'Product');
            },
            targets: 1
          },
          {
            orderable: false,
            targets: 3
          }
        ],
        buttons: buttons,
        search: {
          regex: true,
          smart: false
        },
        order: [[0, "desc"]]
      });

      this.leadPoolDetailsSourceTable.buttons()
          .container()
          .appendTo('#leadPoolSourcesList_wrapper .col-sm-6:eq(0)');

      leadPoolSourcesTable.on('click', '.remove-icon', (e) => {
        _.remove(this.leadPoolDetails.currentSources, (source) => {
          return source.id === this.leadPoolDetailsSourceTable.row($(e.currentTarget).parents('tr')).data().id;
        });
        this.leadPoolDetailsSourceTable.row($(e.currentTarget).parents('tr'))
            .remove()
            .draw();
      });
    },
    createProductStatsDatatable: function () {
      const productStatsTable = $('#productStatsList');
      let buttons = [
        {
          extend: 'pageLength',
          text: '<i class="fas fa-filter"></i>',
          titleAttr: 'Number of Rows'
        },
        {
          extend: 'colvis',
          text: '<i class="fas fa-eye"></i>',
          titleAttr: 'Column Visibility',
          columns: ':not(.noVis)'
        },
        {
          extend: 'excelHtml5',
          autoFilter: true,
          text: '<i class="far fa-file-excel"></i>',
          titleAttr: 'Excel'
        }
      ];

      this.productDetailsStatsTable = productStatsTable.DataTable({
        processing: true,
        language: {
          'loadingRecords': '&nbsp;',
          'processing': '<i class="fas fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'
        },
        dom: 'Brtip',
        paging: false,
        lengthMenu: [
          [10, 25, 50, 100, -1],
          ['10 rows', '25 rows', '50 rows', '100 rows', 'Show all']
        ],
        data: [],
        columns: [
          {
            data: 'label',
          },
          {
            data: 'count',
            defaultContent: '0'
          },
          {
            data: null,
            className: "product-report-icon center",
            defaultContent: '<i class="row-product-report-icon fas fa-file-invoice"/>'
          }
        ],
        columnDefs: [
          {
            render: (data) => {
              if (data) {
                return data.toLocaleString('en-IN');
              } else {
                return 0;
              }
            },
            targets: 1
          },
          {
            orderable: false,
            targets: 2
          }
        ],
        buttons: buttons,
        order: [[1, "desc"]]
      });

      this.productDetailsStatsTable.buttons()
          .container()
          .appendTo('#productStatsTable_wrapper .col-sm-6:eq(0)');

      productStatsTable.on('click', '.product-report-icon', (e) => {
        this.downloadSourcesReport('Product', this.productDetailsStatsTable.row($(e.currentTarget).parents('tr')).data().label);
      });
    },
    createLeadPoolStatsDatatable: function () {
      const leadPoolStatsTable = $('#leadPoolStatsList');
      let buttons = [
        {
          extend: 'pageLength',
          text: '<i class="fas fa-filter"></i>',
          titleAttr: 'Number of Rows'
        },
        {
          extend: 'colvis',
          text: '<i class="fas fa-eye"></i>',
          titleAttr: 'Column Visibility',
          columns: ':not(.noVis)'
        },
        {
          extend: 'excelHtml5',
          autoFilter: true,
          text: '<i class="far fa-file-excel"></i>',
          titleAttr: 'Excel'
        }
      ];

      this.leadPoolDetailsStatsTable = leadPoolStatsTable.DataTable({
        processing: true,
        language: {
          'loadingRecords': '&nbsp;',
          'processing': '<i class="fas fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'
        },
        dom: 'Brtip',
        paging: false,
        lengthMenu: [
          [10, 25, 50, 100, -1],
          ['10 rows', '25 rows', '50 rows', '100 rows', 'Show all']
        ],
        data: [],
        columns: [
          {
            data: 'label',
          },
          {
            data: 'count',
            defaultContent: '0'
          },
          {
            data: null,
            className: "campaign-report-icon center",
            defaultContent: '<i class="row-campaign-report-icon fas fa-file-invoice"/>'
          }
        ],
        columnDefs: [
          {
            render: (data) => {
              if (data) {
                return data.toLocaleString('en-IN');
              } else {
                return 0;
              }
            },
            targets: 1
          },
          {
            orderable: false,
            targets: 2
          }
        ],
        buttons: buttons,
        order: [[1, "desc"]]
      });

      this.leadPoolDetailsStatsTable.buttons()
          .container()
          .appendTo('#leadPoolStatsTable_wrapper .col-sm-6:eq(0)');

      leadPoolStatsTable.on('click', '.campaign-report-icon', (e) => {
        this.downloadSourcesReport('Campaign', this.leadPoolDetailsStatsTable.row($(e.currentTarget).parents('tr')).data().label);
      });
    },
    createSourceStatsDatatable: function () {
      const sourceStatsTable = $('#sourceStatsList');
      let buttons = [
        {
          extend: 'pageLength',
          text: '<i class="fas fa-filter"></i>',
          titleAttr: 'Number of Rows'
        },
        {
          extend: 'colvis',
          text: '<i class="fas fa-eye"></i>',
          titleAttr: 'Column Visibility',
          columns: ':not(.noVis)'
        },
        {
          extend: 'excelHtml5',
          autoFilter: true,
          text: '<i class="far fa-file-excel"></i>',
          titleAttr: 'Excel'
        }
      ];

      this.sourceDetailsStatsTable = sourceStatsTable.DataTable({
        processing: true,
        language: {
          'loadingRecords': '&nbsp;',
          'processing': '<i class="fas fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'
        },
        dom: 'Brtip',
        paging: false,
        lengthMenu: [
          [10, 25, 50, 100, -1],
          ['10 rows', '25 rows', '50 rows', '100 rows', 'Show all']
        ],
        data: [],
        columns: [
          {
            data: 'label',
          },
          {
            data: 'count',
            defaultContent: '0'
          }
        ],
        columnDefs: [
          {
            render: (data) => {
              if (data) {
                return data.toLocaleString('en-IN');
              } else {
                return 0;
              }
            },
            targets: 1
          }
        ],
        buttons: buttons,
        order: [[1, "desc"]]
      });

      this.sourceDetailsStatsTable.buttons()
          .container()
          .appendTo('#sourceStatsTable_wrapper .col-sm-6:eq(0)');
    },
    createLeadPoolDetailsMemberDatatable: function () {
      const leadPoolMembersTable = $('#leadPoolMembersList');
      let buttons = [
        {
          extend: 'pageLength',
          text: '<i class="fas fa-filter"></i>',
          titleAttr: 'Number of Rows'
        },
        {
          extend: 'colvis',
          text: '<i class="fas fa-eye"></i>',
          titleAttr: 'Column Visibility',
          columns: ':not(.noVis)'
        },
        {
          extend: 'excelHtml5',
          autoFilter: true,
          text: '<i class="far fa-file-excel"></i>',
          titleAttr: 'Excel'
        }
      ];

      this.leadPoolDetailsMemberTable = leadPoolMembersTable.DataTable({
        processing: true,
        language: {
          'loadingRecords': '&nbsp;',
          'processing': '<i class="fas fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'
        },
        dom: 'Bfrtip',
        lengthMenu: [
          [10, 25, 50, 100, -1],
          ['10 rows', '25 rows', '50 rows', '100 rows', 'Show all']
        ],
        data: this.leadPoolDetails.currentMembers,
        rowId: 'id',
        columns: [
          {
            data: 'username'
          },
          {
            data: 'supervisor',
            defaultContent: ''
          },
          {
            data: null,
            className: "remove-icon",
            defaultContent: '<i class="row-delete-icon fas fa-trash-alt"/>'
          }
        ],
        columnDefs: [
          {
            render: (data) => {
              return this.prettyPrint(data, 'User');
            },
            targets: 0
          },
          {
            render: (data) => {
              let user = _.find(this.getUsers, (user) => {
                return user.id === data;
              });
              if (user) {
                return _.upperCase(user.firstName + ' ' + user.lastName);
              } else {
                return '';
              }
            },
            targets: 1
          },
          {
            orderable: false,
            targets: 2
          }
        ],
        buttons: buttons,
        search: {
          regex: true,
          smart: false
        },
        order: [[0, "desc"]]
      });

      this.leadPoolDetailsMemberTable.buttons()
          .container()
          .appendTo('#leadPoolMembersList_wrapper .col-sm-6:eq(0)');

      leadPoolMembersTable.on('click', '.remove-icon', (e) => {
        _.remove(this.leadPoolDetails.currentMembers, (member) => {
          return member.id === this.leadPoolDetailsMemberTable.row($(e.currentTarget).parents('tr')).data().id;
        });
        this.leadPoolDetailsMemberTable.row($(e.currentTarget).parents('tr'))
            .remove()
            .draw();
      });
    },
    setupLeadPoolModal: function () {
      $('#leadPoolsList tbody').on('click', 'tr', (e) => {
        this.leadPoolDetails = {};
        this.leadPoolDetails = _.cloneDeep(this.leadPoolsTable.row(e.currentTarget).data());
        if (this.leadPoolDetails) {
          this.leadPoolDetails.currentSources = _.filter(this.sourcesData, (source) => {
            return _.includes(this.leadPoolDetails.sources, source.id);
          });
          this.leadPoolDetails.currentMembers = _.filter(this.getUsers, (user) => {
            return _.includes(this.leadPoolDetails.members, user.username);
          });
          this.leadPoolDetailsSourceTable.clear();
          this.leadPoolDetailsSourceTable.rows.add(this.leadPoolDetails.currentSources);
          this.leadPoolDetailsSourceTable.columns.adjust().draw();

          this.leadPoolDetailsMemberTable.clear();
          this.leadPoolDetailsMemberTable.rows.add(this.leadPoolDetails.currentMembers);
          this.leadPoolDetailsMemberTable.columns.adjust().draw();

          this.updateLeadPoolDetailsChart();
          $("#leadPoolModal").modal('show');
        } else {
          this.leadPoolDetails = {};
        }
      });
    },
    updateLeadPoolDetailsChart: function () {
      let labels = [];
      let data = [];
      let backgroundColours = [];
      let borderColours = [];
      let colourIndex = 0;
      let colourIndexStep = Math.floor(chartColours.length / 8);
      colourIndexStep = colourIndexStep < 1 ? 1 : colourIndexStep;

      data.push(this.leadPoolDetails.untouchedCount);
      labels.push('Untouched Leads');
      backgroundColours.push(chartColours[colourIndex].color);
      borderColours.push(chartColours[colourIndex].border);
      colourIndex += colourIndexStep;

      data.push(this.leadPoolDetails.interestedCount);
      labels.push('Interested Leads');
      backgroundColours.push(chartColours[colourIndex].color);
      borderColours.push(chartColours[colourIndex].border);
      colourIndex += colourIndexStep;

      data.push(this.leadPoolDetails.callLaterCount);
      labels.push('Call Later Leads');
      backgroundColours.push(chartColours[colourIndex].color);
      borderColours.push(chartColours[colourIndex].border);
      colourIndex += colourIndexStep;

      data.push(this.leadPoolDetails.activeCount - this.leadPoolDetails.untouchedCount - this.leadPoolDetails.interestedCount - this.leadPoolDetails.callLaterCount);
      labels.push('Idle Leads');
      backgroundColours.push(chartColours[colourIndex].color);
      borderColours.push(chartColours[colourIndex].border);
      colourIndex += colourIndexStep;

      data.push(this.leadPoolDetails.closedCount);
      labels.push('Closed Leads');
      backgroundColours.push(chartColours[colourIndex].color);
      borderColours.push(chartColours[colourIndex].border);
      colourIndex += colourIndexStep;

      data.push(this.leadPoolDetails.noContactCount);
      labels.push('No Contact Leads');
      backgroundColours.push(chartColours[colourIndex].color);
      borderColours.push(chartColours[colourIndex].border);
      colourIndex += colourIndexStep;

      data.push(this.leadPoolDetails.reusableCount);
      labels.push('Reusable Leads');
      backgroundColours.push(chartColours[colourIndex].color);
      borderColours.push(chartColours[colourIndex].border);
      colourIndex += colourIndexStep;

      data.push(this.leadPoolDetails.unusableCount);
      labels.push('Unusable Leads');
      backgroundColours.push(chartColours[colourIndex].color);
      borderColours.push(chartColours[colourIndex].border);

      let tableData = [];
      let totalCount = 0;

      _.forEach(labels, (label, index) => {
        tableData.push({
          label: label,
          count: data[index]
        });
        totalCount += data[index];
      });
      tableData.push({
        label: "Total",
        count: totalCount
      });

      this.leadPoolDetailsStatsTable.clear();
      this.leadPoolDetailsStatsTable.rows.add(tableData);
      this.leadPoolDetailsStatsTable.columns.adjust().draw();

      this.leadPoolDetailsChartData = {
        labels: labels,
        datasets: [
          {
            backgroundColor: backgroundColours,
            borderColor: borderColours,
            data: data,
          }
        ]
      };
      this.leadPoolDetailsChartOptions = {
        responsive: true,
        layout: {
          padding: {
            left: 50,
            right: 50
          }
        },
        plugins: {
          datalabels: {
            color: '#fff',
          }
        }
      }
    },
    setupSourceModal: function () {
      $('#sourcesList tbody').on('click', 'tr', (e) => {
        this.sourceDetails = {};
        this.sourceDetails = _.cloneDeep(this.sourcesTable.row(e.currentTarget).data());
        if (this.sourceDetails) {
          this.sourceDetails.enabled = this.sourceDetails.enabled === "true";
          this.sourceDetails.priority = priorities[this.sourceDetails.priority];
          if (this.sourceDetails.leadCount !== null && this.sourceDetails.leadCount !== '') {
            this.updateSourceDetailsChart();
            $("#sourceModal").modal('show');
          } else {
            API.graphql(graphqlOperation(queries.getLeadSource, {
              id: this.sourceDetails.id
            })).then((data) => {
              if (data && data.data && data.data.getLeadSource) {
                let fetchedSource = data.data.getLeadSource;
                if (fetchedSource.leadCount !== null && fetchedSource.leadCount !== '') {
                  let oldList = _.cloneDeep(this.sourcesData);
                  let sourceIndex = _.findIndex(oldList, (source) => {
                    return source.id === fetchedSource.id;
                  });
                  if (sourceIndex !== -1) {
                    oldList[sourceIndex] = _.cloneDeep(fetchedSource);
                    this.sourcesData = oldList;
                    this.sourcesTable.row("#" + fetchedSource.id)
                        .invalidate()
                        .data(fetchedSource)
                        .draw();
                    this.sourceDetails = _.cloneDeep(fetchedSource);
                    this.sourceDetails.enabled = fetchedSource.enabled === "true";
                    this.sourceDetails.priority = priorities[this.sourceDetails.priority];
                    this.updateSourceDetailsChart();
                    $("#sourceModal").modal('show');
                  } else {
                    this.sourceDetails = {};
                  }
                } else {
                  this.setMessage('v-notify-warning', "Sorry!", "The leads for this source are still being uploaded!");
                }
              } else {
                this.setMessage('v-notify-error', "Error!", "Something went wrong fetching the source details.");
              }
            });
          }
        } else {
          this.sourceDetails = {};
        }
      });
    },
    updateSourceDetailsChart: function () {
      let labels = [];
      let outerLabels = [];
      let outerData = [];
      let outerBackgroundColours = [];
      let outerBorderColours = [];

      let innerLabels = [];
      let innerData = [];
      let innerBackgroundColours = [];
      let innerBorderColours = [];

      outerData.push(this.sourceDetails.activeCount);
      outerLabels.push('Active Leads');
      outerBackgroundColours.push(chartColours[0].color);
      outerBorderColours.push(chartColours[0].border);

      outerData.push(this.sourceDetails.leadCount - this.sourceDetails.activeCount);
      outerLabels.push('Inactive Leads');
      outerBackgroundColours.push(chartColours[10].color);
      outerBorderColours.push(chartColours[10].border);

      outerData.push(this.sourceDetails.totalCount - this.sourceDetails.leadCount);
      outerLabels.push('Not Uploaded Leads');
      outerBackgroundColours.push(chartColours[15].color);
      outerBorderColours.push(chartColours[15].border);

      labels.push(outerLabels);

      innerData.push(this.sourceDetails.untouchedCount);
      innerLabels.push('Untouched Leads');
      innerBackgroundColours.push(chartColours[1].color);
      innerBorderColours.push(chartColours[1].border);

      innerData.push(this.sourceDetails.interestedCount);
      innerLabels.push('Interested Leads');
      innerBackgroundColours.push(chartColours[2].color);
      innerBorderColours.push(chartColours[2].border);

      innerData.push(this.sourceDetails.callLaterCount);
      innerLabels.push('Call Later Leads');
      innerBackgroundColours.push(chartColours[3].color);
      innerBorderColours.push(chartColours[3].border);

      innerData.push(this.sourceDetails.activeCount - this.sourceDetails.untouchedCount - this.sourceDetails.interestedCount - this.sourceDetails.callLaterCount);
      innerLabels.push('Idle Leads');
      innerBackgroundColours.push(chartColours[4].color);
      innerBorderColours.push(chartColours[4].border);

      innerData.push(this.sourceDetails.closedCount);
      innerLabels.push('Closed Leads');
      innerBackgroundColours.push(chartColours[8].color);
      innerBorderColours.push(chartColours[8].border);

      innerData.push(this.sourceDetails.noContactCount);
      innerLabels.push('No Contact Leads');
      innerBackgroundColours.push(chartColours[11].color);
      innerBorderColours.push(chartColours[11].border);

      innerData.push(this.sourceDetails.reusableCount);
      innerLabels.push('Reusable Leads');
      innerBackgroundColours.push(chartColours[12].color);
      innerBorderColours.push(chartColours[12].border);

      innerData.push(this.sourceDetails.unusableCount);
      innerLabels.push('Unusable Leads');
      innerBackgroundColours.push(chartColours[13].color);
      innerBorderColours.push(chartColours[13].border);

      innerData.push(this.sourceDetails.totalCount - this.sourceDetails.leadCount - (this.sourceDetails.duplicates && this.sourceDetails.duplicates.length > 0 ? this.sourceDetails.duplicates.length : 0));
      innerLabels.push('Invalid Leads');
      innerBackgroundColours.push(chartColours[16].color);
      innerBorderColours.push(chartColours[16].border);

      innerData.push(this.sourceDetails.duplicates && this.sourceDetails.duplicates.length > 0 ? this.sourceDetails.duplicates.length : 0);
      innerLabels.push('Duplicate Leads');
      innerBackgroundColours.push(chartColours[17].color);
      innerBorderColours.push(chartColours[17].border);

      labels.push(innerLabels);

      let tableData = [];
      let totalCount = 0;

      _.forEach(innerLabels, (label, index) => {
        tableData.push({
          label: label,
          count: innerData[index]
        });
        totalCount += innerData[index];
      });
      tableData.push({
        label: "Total",
        count: totalCount
      });

      this.sourceDetailsStatsTable.clear();
      this.sourceDetailsStatsTable.rows.add(tableData);
      this.sourceDetailsStatsTable.columns.adjust().draw();

      this.sourceDetailsChartData = {
        datasets: [
          {
            backgroundColor: outerBackgroundColours,
            borderColor: outerBorderColours,
            data: outerData
          },
          {
            backgroundColor: innerBackgroundColours,
            borderColor: innerBorderColours,
            data: innerData
          }
        ]
      };
      this.sourceDetailsChartOptions = {
        responsive: true,
        layout: {
          padding: {
            left: 50,
            right: 50
          }
        },
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label: (t, d) => {
              return labels[t.datasetIndex][t.index] + ': ' + d.datasets[t.datasetIndex].data[t.index];
            }
          }
        },
        plugins: {
          datalabels: {
            color: '#fff',
          }
        }
      }
    },
    updateTotalStatsChart: function () {
      let labels = [];
      let outerLabels = [];
      let outerData = [];
      let outerBackgroundColours = [];
      let outerBorderColours = [];

      let innerLabels = [];
      let innerData = [];
      let innerBackgroundColours = [];
      let innerBorderColours = [];

      let sources = this.activeProductStats.id === 'total' ? this.sourcesData : _.filter(this.sourcesData, (source) => {
        return source.product === this.activeProductStats.id;
      });

      outerData.push(_.sumBy(sources, 'activeCount'));
      outerLabels.push('Active Leads');
      outerBackgroundColours.push(chartColours[0].color);
      outerBorderColours.push(chartColours[0].border);

      outerData.push(_.sumBy(sources, 'leadCount') - _.sumBy(sources, 'activeCount'));
      outerLabels.push('Inactive Leads');
      outerBackgroundColours.push(chartColours[10].color);
      outerBorderColours.push(chartColours[10].border);

      outerData.push(_.sumBy(sources, 'totalCount') - _.sumBy(sources, 'leadCount'));
      outerLabels.push('Not Uploaded Leads');
      outerBackgroundColours.push(chartColours[15].color);
      outerBorderColours.push(chartColours[15].border);

      labels.push(outerLabels);

      innerData.push(_.sumBy(sources, 'untouchedCount'));
      innerLabels.push('Untouched Leads');
      innerBackgroundColours.push(chartColours[1].color);
      innerBorderColours.push(chartColours[1].border);

      innerData.push(_.sumBy(sources, 'interestedCount'));
      innerLabels.push('Interested Leads');
      innerBackgroundColours.push(chartColours[2].color);
      innerBorderColours.push(chartColours[2].border);

      innerData.push(_.sumBy(sources, 'callLaterCount'));
      innerLabels.push('Call Later Leads');
      innerBackgroundColours.push(chartColours[3].color);
      innerBorderColours.push(chartColours[3].border);

      innerData.push(_.sumBy(sources, 'activeCount') - _.sumBy(sources, 'untouchedCount') - _.sumBy(sources, 'interestedCount') - _.sumBy(sources, 'callLaterCount'));
      innerLabels.push('Idle Leads');
      innerBackgroundColours.push(chartColours[4].color);
      innerBorderColours.push(chartColours[4].border);

      innerData.push(_.sumBy(sources, 'closedCount'));
      innerLabels.push('Closed Leads');
      innerBackgroundColours.push(chartColours[8].color);
      innerBorderColours.push(chartColours[8].border);

      innerData.push(_.sumBy(sources, 'noContactCount'));
      innerLabels.push('No Contact Leads');
      innerBackgroundColours.push(chartColours[11].color);
      innerBorderColours.push(chartColours[11].border);

      innerData.push(_.sumBy(sources, 'reusableCount'));
      innerLabels.push('Reusable Leads');
      innerBackgroundColours.push(chartColours[12].color);
      innerBorderColours.push(chartColours[12].border);

      innerData.push(_.sumBy(sources, 'unusableCount'));
      innerLabels.push('Unusable Leads');
      innerBackgroundColours.push(chartColours[13].color);
      innerBorderColours.push(chartColours[13].border);

      innerData.push(_.sumBy(sources, 'totalCount') - _.sumBy(sources, 'leadCount') - _.sumBy(sources, (source) => {
        return source.duplicates && source.duplicates.length > 0 ? source.duplicates.length : 0;
      }));
      innerLabels.push('Invalid Leads');
      innerBackgroundColours.push(chartColours[16].color);
      innerBorderColours.push(chartColours[16].border);

      innerData.push(_.sumBy(sources, (source) => {
        return source.duplicates && source.duplicates.length > 0 ? source.duplicates.length : 0;
      }));
      innerLabels.push('Duplicate Leads');
      innerBackgroundColours.push(chartColours[16].color);
      innerBorderColours.push(chartColours[16].border);

      labels.push(innerLabels);

      let tableData = [];
      let totalCount = 0;

      _.forEach(innerLabels, (label, index) => {
        tableData.push({
          label: label,
          count: innerData[index]
        });
        totalCount += innerData[index];
      });
      tableData.push({
        label: "Total",
        count: totalCount
      });

      this.productDetailsStatsTable.clear();
      this.productDetailsStatsTable.rows.add(tableData);
      this.productDetailsStatsTable.columns.adjust().draw();

      this.totalStatsChartData = {
        datasets: [
          {
            backgroundColor: outerBackgroundColours,
            borderColor: outerBorderColours,
            data: outerData,
            weight: 1
          },
          {
            backgroundColor: innerBackgroundColours,
            borderColor: innerBorderColours,
            data: innerData,
            weight: 2
          }
        ]
      };
      this.totalStatsChartOptions = {
        responsive: true,
        layout: {
          padding: {
            left: 25,
            right: 25
          }
        },
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label: (t, d) => {
              return labels[t.datasetIndex][t.index] + ': ' + d.datasets[t.datasetIndex].data[t.index];
            }
          }
        },
        plugins: {
          datalabels: {
            color: '#fff',
          }
        }
      }
    },
    downloadSourcesReport(type, key = null) {
      let filename = null;
      let sources = null;

      if (type === 'Product' && this.activeProductStats) {
        filename = this.activeProductStats.id === 'total' ? `All-Sources${key ? '-' + key : ''}.csv` : this.prettyPrint(this.activeProductStats.id, 'Product') + `-Sources${key ? '-' + key : ''}.csv`;
        sources = this.activeProductStats.id === 'total' ? this.sourcesData : _.filter(this.sourcesData, (source) => {
          return source.product === this.activeProductStats.id;
        });
      } else if (type === 'Campaign' && this.leadPoolDetails) {
        filename = `${this.leadPoolDetails.name}-Sources${key ? '-' + key : ''}.csv`;
        sources = this.leadPoolDetails.currentSources;
      } else {
        return;
      }
      let reportData = [];
      if (sources && _.isObject(sources)) {
        _.forEach(_.orderBy(sources, (source) => {
          return moment(source.createdAt).format('X');
        }, ['desc']), (source) => {
          if (key) {
            const keyValue = {};
            switch (key) {
              case "Upload Count":
              case "Total":
                keyValue[key] = source.totalCount;
                break;
              case "Invalid Leads":
                keyValue[key] = source.totalCount - source.leadCount - (source.duplicates && source.duplicates.length > 0 ? source.duplicates.length : 0);
                break;
              case "Duplicate Leads":
                keyValue[key] = source.duplicates && source.duplicates.length > 0 ? source.duplicates.length : 0;
                break;
              case "Generated Leads":
                keyValue[key] = source.leadCount;
                break;
              case "Untouched Leads":
                keyValue[key] = source.untouchedCount || 0;
                break;
              case "Idle Leads":
                keyValue[key] = source.activeCount - source.untouchedCount - source.interestedCount - source.callLaterCount;
                break;
              case "Closed Leads":
                keyValue[key] = source.closedCount || 0;
                break;
              case "Interested Leads":
                keyValue[key] = source.interestedCount || 0;
                break;
              case "Call Later Leads":
                keyValue[key] = source.callLaterCount || 0;
                break;
              case "No Contact Leads":
                keyValue[key] = source.noContactCount || 0;
                break;
              case "Reusable Leads":
                keyValue[key] = source.reusableCount || 0;
                break;
              case "Unusable Leads":
                keyValue[key] = source.unusableCount || 0;
                break;
            }
            if (keyValue[key] > 0) {
              reportData.push({
                Name: source.name,
                "Upload Date": this.prettyPrint(source.createdAt, 'Date'),
                Enabled: source.enabled,
                "Priority": this.prettyPrint(source.priority, 'Priority'),
                ...keyValue
              });
            }
          } else {
            reportData.push({
              Name: source.name,
              "Upload Date": this.prettyPrint(source.createdAt, 'Date'),
              Enabled: source.enabled,
              "Priority": this.prettyPrint(source.priority, 'Priority'),
              "Upload Count": source.totalCount,
              "Invalid Leads": source.totalCount - source.leadCount - (source.duplicates && source.duplicates.length > 0 ? source.duplicates.length : 0),
              "Duplicate Leads": source.duplicates && source.duplicates.length > 0 ? source.duplicates.length : 0,
              "Generated Leads": source.leadCount,
              "Untouched Leads": source.untouchedCount || 0,
              "Idle Leads": source.activeCount - source.untouchedCount - source.interestedCount - source.callLaterCount,
              "Closed Leads": source.closedCount || 0,
              "Interested Leads": source.interestedCount || 0,
              "Call Later Leads": source.callLaterCount || 0,
              "No Contact Leads": source.noContactCount || 0,
              "Reusable Leads": source.reusableCount || 0,
              "Unusable Leads": source.unusableCount || 0
            });
          }
        });
        const csv = Papa.unparse(reportData);
        const blob = csv.constructor !== Blob
            ? new Blob([csv], {type: 'text/csv;charset=utf-8;'})
            : csv;

        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, filename);
          return;
        }

        let lnk = document.createElement('a'),
            url = window.URL,
            objectURL;

        lnk.type = 'text/csv;charset=utf-8;';
        lnk.download = filename || 'untitled';
        lnk.href = objectURL = url.createObjectURL(blob);
        lnk.dispatchEvent(new MouseEvent('click'));
        setTimeout(url.revokeObjectURL.bind(url, objectURL));
      }
    },
    downloadSourceDuplicatesReport() {
      if (this.sourceDetails && this.sourceDetails.duplicates && this.sourceDetails.duplicates.length > 0) {
        let duplicates = _.map(this.sourceDetails.duplicates, (duplicate) => {
          let keyValue = _.split(duplicate, ':');
          return {
            "Source Name": this.prettyPrint(keyValue[0], 'Source') === keyValue[0] ? 'DELETED SOURCE' : this.prettyPrint(keyValue[0], 'Source'),
            "Leads Count": _.parseInt(keyValue[1])
          }
        });
        const filename = this.sourceDetails.name + "-Duplicates.csv";
        const csv = Papa.unparse(_.orderBy(duplicates, 'Leads Count', ['desc']));
        const blob = csv.constructor !== Blob
            ? new Blob([csv], {type: 'text/csv;charset=utf-8;'})
            : csv;

        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, filename);
          return;
        }

        let lnk = document.createElement('a'),
            url = window.URL,
            objectURL;

        lnk.type = 'text/csv;charset=utf-8;';
        lnk.download = filename || 'untitled';
        lnk.href = objectURL = url.createObjectURL(blob);
        lnk.dispatchEvent(new MouseEvent('click'));
        setTimeout(url.revokeObjectURL.bind(url, objectURL));
      }
    },
    prettyPrint: function (value, type) {
      if (type === 'Date') {
        if (_.includes(value, 'T')) {
          return moment(value).format('MMMM Do YYYY h:mm A');
        } else if (!value || value === '') {
          return '';
        } else {
          return moment(value, "YYYY-MM-DD").format('DD-MM-YYYY');
        }
      } else if (type === 'Epoch') {
        if (!value || value === '') {
          return '';
        } else {
          return moment(value, 'X').format('MMMM Do YYYY h:mm A');
        }
      } else if (type === 'User') {
        let user = _.find(this.getUsers, (user) => {
          return user.username === value;
        });
        if (user) {
          return _.upperCase(user.firstName + ' ' + user.lastName);
        } else {
          return value;
        }
      } else if (type === 'Source') {
        let sourceName = _.result(_.find(this.sourcesData, (source) => {
          return source.id === value;
        }), 'name');
        if (sourceName) {
          return sourceName;
        } else {
          return value;
        }
      } else if (type === 'Product') {
        let productName = _.result(_.find(this.getProducts, (product) => {
          return product.id === value;
        }), 'name');
        if (productName) {
          return productName;
        } else {
          return value;
        }
      } else if (type === 'Priority') {
        let priorityName = _.result(priorities[value], 'name');
        if (priorityName) {
          return priorityName;
        } else {
          return value;
        }
      }
    },
    addNew: function (scope) {
      if (scope === 'source') {
        $('#uploadFile').attr('disabled', true);
      } else if (scope === 'pool') {
        $('#createLeadPool').attr('disabled', true);
      } else if (scope === 'leadPoolDetails') {
        $('#updatePoolDetailsButton').attr('disabled', true);
      } else if (scope === 'sourceDetails') {
        $('#updateSourceDetailsButton').attr('disabled', true);
      }
      this.$validator.validateAll(scope)
          .then((result) => {
            if (result) {
              if (scope === 'source') {
                this.uploadFile();
              } else if (scope === 'pool') {
                this.newLeadPool();
              } else if (scope === 'leadPoolDetails') {
                this.updatePoolDetails();
              } else if (scope === 'sourceDetails') {
                this.updateSourceDetails()
              }
            } else {
              if (scope === 'source') {
                $('#uploadFile').attr('disabled', false);
              } else if (scope === 'pool') {
                $('#createLeadPool').attr('disabled', false);
              } else if (scope === 'leadPoolDetails') {
                $('#updatePoolDetailsButton').attr('disabled', false);
              } else if (scope === 'sourceDetails') {
                $('#updateSourceDetailsButton').attr('disabled', false);
              }
              let firstErrorInput = $('input.error:first')[0] || $('.multiselect.error:first') [0];
              let errorInputName = firstErrorInput.dataset.vvAs || firstErrorInput.name;
              if (errorInputName && errorInputName !== '') {
                this.setMessage('v-notify-error', "So Close!", "There is an issue with the " + errorInputName + " field.");
              } else {
                this.setMessage('v-notify-error', "Sorry!", "Unable to complete action at this time!");
              }
            }
          })
          .catch(() => {
            if (scope === 'source') {
              $('#uploadFile').attr('disabled', false);
            } else if (scope === 'pool') {
              $('#createLeadPool').attr('disabled', false);
            } else if (scope === 'leadPoolDetails') {
              $('#updatePoolDetailsButton').attr('disabled', false);
            } else if (scope === 'sourceDetails') {
              $('#updateSourceDetailsButton').attr('disabled', false);
            }
            let firstErrorInput = $('input.error:first')[0] || $('.multiselect.error:first') [0];
            let errorInputName = firstErrorInput.dataset.vvAs || firstErrorInput.name;
            if (errorInputName && errorInputName !== '') {
              this.setMessage('v-notify-error', "So Close!", "There is an issue with the " + errorInputName + " field.");
            } else {
              this.setMessage('v-notify-error', "Sorry!", "Unable to complete action at this time!");
            }
          });
    },
    async uploadFile() {
      if (!this.sourceProduct || this.sourceProduct === '') {
        $('#uploadFile').attr('disabled', false);
        this.setMessage('v-notify-error', "Try Again!", "You must select a product type!");
        return;
      }
      if (!this.sourcePriority || this.sourcePriority === '') {
        $('#uploadFile').attr('disabled', false);
        this.setMessage('v-notify-error', "So Close!", "You must select source priority!");
        return;
      }
      let files = this.fileUploader.getQueuedFiles();
      if (!files || files.length < 1) {
        $('#uploadFile').attr('disabled', false);
        this.setMessage('v-notify-error', "Error!", "You must include a csv file to upload!");
        return;
      }
      this.setMessage('confirm', 'Are you sure?', 'You are about to upload a new source! Make sure all details are correct.').then((data) => {
        if (data && data.data) {
          this.uploadFileConfirmed(files);
        } else {
          $('#uploadFile').attr('disabled', false);
        }
      });
    },
    async uploadFileConfirmed(files) {
      const currentTimeEpoch = moment().format('X');
      try {
        this.uploadStatus = uploadStatuses.progress;
        let newSource = {
          name: this.sourceName,
          enabled: "false",
          product: this.sourceProduct.id,
          priority: this.sourcePriority.id
        };
        if (this.sourceAutoEnable) {
          newSource.enableAtEpoch = parseInt(moment(this.sourceEnableAt.startDate).format('X'));
          newSource.enabled = "scheduled";
        }
        if (!this.sourceAutoAllocate && this.sourceCampaignMembers && this.sourceCampaignMembers.length > 0) {
          newSource.allocatedMembers = _.map(_.filter(this.sourceCampaignMembers, (member) => {
            return member.allocationCount && _.parseInt(member.allocationCount);
          }), (member) => {
            return member.username + ':' + _.parseInt(member.allocationCount);
          });
        }
        const {
          data: {createLeadSource}
        } = await API.graphql(graphqlOperation(mutations.createLeadSource, {
          input: newSource
        }));
        if (this.sourceCampaign && this.sourceCampaign !== '') {
          if (!this.sourceCampaign.sources || this.sourceCampaign.sources === '') {
            this.sourceCampaign.sources = [createLeadSource.id];

          } else {
            this.sourceCampaign.sources.push(createLeadSource.id);
          }
          await this.updatePoolDetailsConfirmed({
            id: this.sourceCampaign.id,
            sources: this.sourceCampaign.sources,
            updatedAtEpoch: currentTimeEpoch,
            expectedVersion: this.sourceCampaign.version
          });
        }
        this.sourceName = null;
        this.sourceProduct = null;
        this.sourcePriority = null;
        this.sourceCampaign = null;
        this.sourceCampaignMembers = [];
        this.sourceAutoEnable = false;
        this.sourceAutoAllocate = true;
        this.sourceEnableAt = {
          startDate: moment(currentTimeEpoch, 'X').add(1, 'hours').startOf('hour')
        };
        this.errors.clear();
        await this.$validator.reset();

        let sourceFile = files[0];
        let fileName = createLeadSource.id + '.csv';

        await Storage.put(fileName, sourceFile, {
          level: 'private',
          contentType: sourceFile.type
        });
        this.fileUploader.removeAllFiles();
        this.uploadStatus = uploadStatuses.success;
        $('#uploadFile').attr('disabled', false);
        this.setMessage('v-notify-success', "Congratulations!", "The source was created successfully!");
        this.setMessage('v-notify-info', "Note", "The leads will be uploaded in the next few minutes.");
      } catch (err) {
        this.uploadStatus = uploadStatuses.error;
        $('#uploadFile').attr('disabled', false);
        this.setMessage('v-notify-error', "Error!", err);
      }
    },
    async newLeadPool() {
      const {
        data: {
          leadPoolsByName: {items}
        }
      } = await API.graphql(graphqlOperation(customQueries.countLeadPoolsByName, {
        name: this.leadPoolName,
        limit: 999
      }));
      if (items.length > 0) {
        $('#uploadFile').attr('disabled', false);
        this.setMessage('v-notify-error', "Oops!", 'A campaign with the given name already exists!"');
        return;
      }
      try {
        let newLeadPool = {
          name: this.leadPoolName,
          updatedAtEpoch: moment().format('X')
        };
        if (this.leadPoolSources && this.leadPoolSources.length > 0) {
          newLeadPool.sources = _.map(this.leadPoolSources, 'id');
        }
        if (this.leadPoolMembers && this.leadPoolMembers.length > 0) {
          newLeadPool.members = _.map(this.leadPoolMembers, 'username');
        }
        await API.graphql(graphqlOperation(mutations.createLeadPool, {
          input: newLeadPool
        }));
        this.leadPoolName = '';
        this.leadPoolSources = [];
        this.leadPoolMembers = [];
        this.errors.clear();
        await this.$validator.reset();
        $('#createLeadPool').attr('disabled', false);
        this.setMessage('v-notify-success', "Awesome!", "The campaign was created successfully!");
      } catch (err) {
        $('#createLeadPool').attr('disabled', false);
        this.setMessage('v-notify-error', "Error!", err);
      }
    },
    async updatePoolDetails() {
      const {
        data: {
          leadPoolsByName: {items}
        }
      } = await API.graphql(graphqlOperation(customQueries.countLeadPoolsByName, {
        name: this.leadPoolDetails.name,
        limit: 999
      }));
      if (items.length > 1 || (items.length === 1 && items[0].id !== this.leadPoolDetails.id)) {
        $('#updatePoolDetailsButton').attr('disabled', false);
        this.setMessage('v-notify-error', "Oops!", 'A campaign with the given name already exists!"');
        return;
      }
      try {
        let updateDetails = _.omit(this.leadPoolDetails, ['version', '__typename', 'updatedAt', 'logs', 'currentSources', 'newSources', 'currentMembers', 'newMembers']);
        updateDetails.updatedAtEpoch = moment().format('X');
        updateDetails.expectedVersion = this.leadPoolDetails.version;
        updateDetails.sources = _.map(_.concat(_.compact(this.leadPoolDetails.currentSources), _.compact(this.leadPoolDetails.newSources)), 'id');
        updateDetails.members = _.map(_.concat(_.compact(this.leadPoolDetails.currentMembers), _.compact(this.leadPoolDetails.newMembers)), 'username');
        await this.updatePoolDetailsConfirmed(updateDetails);

        $('#updatePoolDetailsButton').attr('disabled', false);
        this.setMessage('v-notify-success', "Nice!", "The campaign was updated successfully!");
        $("#leadPoolModal").modal('hide');
      } catch (e) {
        $('#updatePoolDetailsButton').attr('disabled', false);
        this.setMessage('v-notify-error', "Error!", e);
      }
    },
    async updatePoolDetailsConfirmed(poolDetails) {
      try {
        const {
          data: {updateLeadPool}
        } = await API.graphql(graphqlOperation(mutations.updateLeadPool, {
          input: _.omitBy(poolDetails, (field) => {
            return (!field) || field === ''
          })
        }));

        let oldList = _.cloneDeep(this.leadPoolsData);
        let poolIndex = _.findIndex(oldList, (pool) => {
          return pool.id === updateLeadPool.id;
        });
        if (poolIndex !== -1) {
          oldList[poolIndex] = updateLeadPool;
        }

        this.leadPoolsTable.row("#" + updateLeadPool.id)
            .invalidate()
            .data(updateLeadPool)
            .draw();
      } catch (e) {
        $('#updatePoolDetailsButton').attr('disabled', false);
        this.setMessage('v-notify-error', "Error!", e);
      }
    },
    async updateSourceDetails() {
      const currentTimeEpoch = moment().format('X');
      let updateDetails = _.omit(this.sourceDetails, ['version', '__typename', 'allocatedMembers', 'createdAt', 'createdAtEpoch', 'updatedAt', 'logs', 'product']);
      updateDetails.expectedVersion = this.sourceDetails.version;
      updateDetails.priority = this.sourceDetails.priority.id;
      updateDetails.updatedAtEpoch = currentTimeEpoch;
      if (updateDetails.enabled) {
        let oldSourceDetails = _.find(this.sourcesData, (source) => {
          return source.id === updateDetails.id;
        });
        if (oldSourceDetails.enabled === "false") {
          updateDetails.enableAtEpoch = currentTimeEpoch;
        }
        let existingPoolSources = _.flattenDeep(_.map(this.leadPoolsData, 'sources'));
        if (_.includes(existingPoolSources, updateDetails.id)) {
          this.updateSourceDetailsConfirmed(updateDetails);
        } else {
          this.setMessage('confirm', 'Are you sure?', 'This source is not linked to a Campaign! Are you sure you want to release it to the common pool?').then((data) => {
            if (data && data.data) {
              this.updateSourceDetailsConfirmed(updateDetails);
            } else {
              $('#updateSourceDetailsButton').attr('disabled', false);
            }
          });
        }
      } else {
        updateDetails.enableAtEpoch = null;
        this.updateSourceDetailsConfirmed(updateDetails);
      }
    },
    async updateSourceDetailsConfirmed(updateDetails) {
      try {
        const {
          data: {updateLeadSource}
        } = await API.graphql(graphqlOperation(mutations.updateLeadSource, {
          input: updateDetails
        }));

        let oldList = _.cloneDeep(this.sourcesData);
        let sourceIndex = _.findIndex(oldList, (source) => {
          return source.id === updateLeadSource.id;
        });
        if (sourceIndex !== -1) {
          oldList[sourceIndex] = updateLeadSource;
        }

        $('#updateSourceDetailsButton').attr('disabled', false);
        this.setMessage('v-notify-success', "Nice!", "The source was updated successfully!");
        this.sourcesTable.row("#" + updateLeadSource.id)
            .invalidate()
            .data(updateLeadSource)
            .draw();
        $("#sourceModal").modal('hide');
      } catch (e) {
        $('#updateSourceDetailsButton').attr('disabled', false);
        this.setMessage('v-notify-error', "Error!", e);
      }
    },
    deleteSelectedSource: function () {
      $('#deleteSelectedSource').attr('disabled', true);
      this.setMessage('confirm', 'Are you sure?', 'You are about to permanently delete this source!').then((data) => {
        if (data && data.data) {
          this.deleteSourceConfirmed();
        } else {
          $('#deleteSelectedSource').attr('disabled', false);
        }
      });
    },
    async deleteSourceConfirmed() {
      try {
        await API.graphql(graphqlOperation(mutations.deleteLeadSource, {
          input: {
            id: this.sourceDetails.id,
            expectedVersion: this.sourceDetails.version
          }
        }));
        let pool = _.find(this.leadPoolsData, (pool) => {
          return _.includes(pool.sources, this.sourceDetails.id);
        });
        if (pool && pool !== '') {
          let sources = _.cloneDeep(pool.sources);
          _.remove(sources, (source) => {
            return source === this.sourceDetails.id;
          });
          this.updatePoolDetailsConfirmed({
            id: pool.id,
            sources: sources,
            expectedVersion: pool.version
          })
        }
        let oldSources = _.cloneDeep(this.sourcesData);
        _.remove(oldSources, (source) => {
          return source.id === this.sourceDetails.id;
        });
        this.sourcesData = oldSources;
        this.sourcesTable.row("#" + this.sourceDetails.id)
            .remove()
            .draw();
        $('#deleteSelectedSource').attr('disabled', false);
        $("#sourceModal").modal('hide');
      } catch (err) {
        $('#deleteSelectedSource').attr('disabled', false);
        this.setMessage('v-notify-error', 'Sorry!', err);
      }
    },
    deleteSelectedPool: function () {
      $('#deleteSelectedPool').attr('disabled', true);
      this.setMessage('confirm', 'Are you sure?', 'You are about to permanently delete this campaign!').then((data) => {
        if (data && data.data) {
          this.deletePoolConfirmed();
        } else {
          $('#deleteSelectedPool').attr('disabled', false);
        }
      });
    },
    async deletePoolConfirmed() {
      try {
        await API.graphql(graphqlOperation(mutations.deleteLeadPool, {
          input: {
            id: this.leadPoolDetails.id,
            expectedVersion: this.leadPoolDetails.version
          }
        }));
        let oldPools = _.cloneDeep(this.leadPoolsData);
        _.remove(oldPools, (pool) => {
          return pool.id === this.leadPoolDetails.id;
        });
        this.leadPoolsData = oldPools;
        this.leadPoolsTable.row("#" + this.leadPoolDetails.id)
            .remove()
            .draw();
        $('#deleteSelectedPool').attr('disabled', false);
        $("#leadPoolModal").modal('hide');
      } catch (err) {
        $('#deleteSelectedPool').attr('disabled', false);
        this.setMessage('v-notify-error', 'Sorry!', err);
      }
    }
  }
}
</script>

<style scoped>

</style>
